




























































































































































import Vue from 'vue';
import moment from 'moment';
import { downloadFile } from '@/utils/index';
import * as voucheApi from '@/api/offlineVouche';
const initData = {
  actId: '',
  name: '', //活动名称
  firstPrice: undefined, //满减（元）
  voucherDefinitionCode: '', //活动批次号
  price: undefined, //面额（元）
  generateCount: undefined, //生成数量
  redeemCodeDeadline: [], //兑换码兑换有效期
  startTime: undefined, //兑换码兑换有效期开始时间
  endTime: undefined, //兑换码兑换有效期结束时间
  availableApp: undefined, //使用范围ID
  availableName: '', //使用范围名称
  expiredType: undefined, //有效期类型
  validDay: undefined, //代金券使用有效期
  voucheDeadline: [], //代金券使用有效期(范围)
  description: '', //使用说明
  sequenceNbr: '', //流水号
  productNames: '', //适用范围
};
export default Vue.extend({
  name: 'activityDetail',
  props: [],
  data() {
    return {
      actId: '',
      showModal: false,
      exportBtnLoading: false,
      basicInfoLoading: false,
      basicData: {} as any,
      tableLoading: false,
      searchForm: {
        redeemCode: '', // 兑换码
      },
      filteredInfo: {
        hasRedeemed: [], // 兑换状态
        hasUsed: [], // 使用状态
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      redeemCodeData: [] as any,
    };
  },
  computed: {
    redeemCodeColumns() {
      const redeemCodeColumns = [
        {
          title: '兑换码',
          dataIndex: 'redeemCode',
          align: 'center',
        },
        {
          title: '兑换状态',
          dataIndex: 'hasRedeemed',
          align: 'center',
          scopedSlots: { customRender: 'hasRedeemed' },
          filters: [
            { text: '已兑换', value: 'Y' },
            { text: '未兑换', value: 'N' },
            // { text: '已过期', value: 'X' },
          ],
          filteredValue: this['filteredInfo'].hasRedeemed,
        },
        {
          title: '满(元)',
          dataIndex: 'firstPrice',
          align: 'center',
        },
        {
          title: '减(元)',
          dataIndex: 'price',
          align: 'center',
        },
        {
          title: '兑换时间',
          dataIndex: 'redeemedTime',
          scopedSlots: { customRender: 'redeemedTime' },
          align: 'center',
        },
        {
          title: '兑换是否过期',
          dataIndex: 'ifRedeemExpired',
          scopedSlots: { customRender: 'ifRedeemExpired' },
          align: 'center',
        },
        {
          title: '使用用户',
          dataIndex: 'userName',
          align: 'center',
        },
        {
          title: '所属租户',
          dataIndex: 'agencyName',
          align: 'center',
        },
        {
          title: '使用状态',
          dataIndex: 'hasUsed',
          align: 'center',
          scopedSlots: { customRender: 'hasUsed' },
          filters: [
            { text: '已使用', value: 'Y' },
            { text: '未使用', value: 'N' },
          ],
          filteredValue: this['filteredInfo'].hasUsed,
        },
        {
          title: '使用时间',
          dataIndex: 'usedTime',
          scopedSlots: { customRender: 'usedTime' },
          align: 'center',
        },
        {
          title: '使用是否过期',
          dataIndex: 'ifVoucherExpired',
          scopedSlots: { customRender: 'ifVoucherExpired' },
          align: 'center',
        },
      ];
      return redeemCodeColumns;
    },
  },
  methods: {
    moment,
    show(record, redeemCode) {
      this.basicData = {};
      this.redeemCodeData = [];
      this.actId = record.actId;
      this.showModal = true;
      this.searchForm.redeemCode = redeemCode; //携带主列表传来的兑换码，用于二次搜索
      this.getRedeemCodeData();
      this.basicInfoLoading = true;
      voucheApi
        .getOfflineVoucheDetail(record.sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.basicData = res.result;
          }
        })
        .finally(() => {
          this.basicInfoLoading = false;
        });
      // this.initBasicData(record);
    },
    // 初始化基础数据
    initBasicData(record) {
      this.basicData = record;
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getRedeemCodeData();
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.clearTableFilters();
      // (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.searchForm.redeemCode = '';
      this.pagination.current = 1;
      this.getRedeemCodeData();
    },
    // 对话框关闭事件
    handleModalCancel() {
      this.clearTableFilters();
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      // (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.searchForm.redeemCode = '';
    },
    // 清空表格过滤条件
    clearTableFilters() {
      this.filteredInfo.hasRedeemed = [];
      this.filteredInfo.hasUsed = [];
    },
    // 获取兑换码列表数据
    getRedeemCodeData() {
      this.tableLoading = true;
      const actId = this.actId;
      const redeemCode = this.searchForm.redeemCode;
      const hasRedeemed = this.filteredInfo.hasRedeemed.join(',');
      const hasUsed = this.filteredInfo.hasUsed.join(',');
      voucheApi
        .getRedeemCodeData(
          actId,
          redeemCode,
          hasRedeemed,
          hasUsed,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.redeemCodeData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination, filters) {
      // console.log(pagination,filters);
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.filteredInfo = filters;
      this.getRedeemCodeData();
    },
    handleExportAllRecords() {
      this.exportBtnLoading = true;
      voucheApi
        .detailsExport(this.basicData.voucherDefinitionCode)
        .then((res: any) => {
          this.exportBtnLoading = false;
          downloadFile(res, this.basicData.name + '_兑换码使用情况');
        });
    },
  },
});
